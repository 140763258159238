.VibeLoadMore{
    display: flex;
    width: 100%;
    justify-content: center;

}
.VibeLoadMoreButton{
    width: 120px;
    height: 44px;
    border: 1px solid #202225;
    background: #0F1112;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    cursor: pointer;
    margin: 30px auto;
}
.ShowVibeListLoading{
    width:100%;
    height: 44px;
    display: flex;
    justify-content: center;
    margin: 30px auto;
}
.VibeList{
    display: flex;
    justify-content:start;
    flex-wrap: wrap;
    padding: 20px;
}
.VibeItem{
    display: flex;
    width: 559px;
    background: #0F1112;
    border: 1px solid #202225;
    border-radius: 4px;
    padding: 20px;
    flex-direction: column;
    margin-bottom: 20px;
}



.VibeItemTags{
    display: flex;
    margin-top: 20px;
    
}
.VibeItemTop{
    display: flex;
    justify-content: space-between;
}
.VibeItemDetail{
 
    margin:20px 0px 0px;

white-space: pre-wrap;

}
.VibeItemImage{
    margin-top: 20px;
}
.VibeItemQuote{
    cursor: pointer;
}
.VibeCategory{
    cursor: pointer;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #202225;
    margin-top: 2px;
    margin-right: 6px;
}
.VibeEventArea{
    cursor: pointer;
}
.VibeItemAvatar{
    width: 42px;
    height: 42px;
    border-radius: 100px;
    overflow: hidden;
}
.VibeItemNickname{
    margin-left: 10px;
}
.VibeItemAddress{
    margin-left: 10px;
}
.VibeItemHandle{
    color: #959798;
    margin-left: 10px;
    font-size: 14px;
}

.VibeItemUserInfo{
    cursor: pointer;
    align-items: center;
    flex-direction: row;
    display: flex;
}
.VibeItemDate{
    height: 42px;
    line-height: 42px;
    color: #959798;
    font-size: 14px;
}
.VibeEventArea a{
    text-decoration: none;
    color: #1D9BF0;
}
.VibeEventArea a:hover{
    text-decoration: underline;
}
.VibeListNoData{
    padding: 20px 0px;
    width: 100%;
    text-align: center;
}


@media only screen and (max-width: 576px) {
    .VibeEventArea a{
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
        width: 300px;
    }
    
}



