.Navigator {
    margin-top: 20px;
}



.Navigator a {
    padding: 20px 0px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}


.Navigator a p {
    margin-left: 10px;
}