.LeftBar {
    width: 259px;
    height: 100vh;
    position: fixed;
    border-right: 1px solid #202225;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}




.Logo {
    margin: 20px 0px;
}

.Slogan {
    color: #959798;
    margin-top: 10px;
    margin-bottom: 20px;
}

.LeftBarBottom {
    padding-bottom: 40px;
}

.SocialLink {
    display: flex;
}

.SocialLink li {
    margin-right: 10px;
}

.Copyright {
    font-size: 14px;
    color: #959798;
}

.PoweredBy{
    margin-bottom: 10px;
}
.PoweredBy p{
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    color: #959798;
    margin-right: 4px;
}
.PoweredBy a{
    display: flex;
    align-items: center;
    text-decoration: none;
}