.MobileHeaderBg{
    position: fixed;
    background: #000;
    width: 100%;
    z-index: 10;
    border-bottom: 1px solid #202225;
}
.MobileHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    margin: 0px auto;
    padding: 10px 0px;

}
.MobileHeaderLogo{
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
}
.MobileHeadRight{
    display: flex;
    align-items: center;

}
.MobileMenuButton{
    width: 36px;
    height: 36px;
    margin-left: 10px;
}
.ant-drawer .ant-drawer-content {
    background: #0F1112;
}
