.uploadVibeImageButton{
    border: 1px solid #333333;
    background: #272727;
    border-radius: 4px;
    padding: 10px;
    align-items: center;
    width: 24px;
    height: 24px;
}
.uploadAvatarImageButton{
    margin: 0px auto;
    margin-top: 20px;
    width: 84px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #272727;
    border-radius: 100px;
}

.uploadImageButton_txt{
    color:#fff;

}
.upload_tip{
    color: #BFBFBF;
}
.ant-upload-wrapper{
    cursor: pointer;
    display: block;
    height: auto;
    line-height: 0px;
}
.ImageUpload{
    height: auto;
}
.ant-upload{
    display: block;
    width: 100%;
}
.showSelectedImage{
    justify-content: space-between;
    display: flex;
}
.removeImageButton{
    
}