.ProfileEditor{
    padding: 20px;
    .ProfileEditorTitle{
        font-size: 18px;
    }
    .ant-input-affix-wrapper .ant-input-show-count-suffix{
        color: #fff;
    }
    .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled){
        background: none;
    }
    .HandleTipError{
        color: #ff4d4f;
    }
    .HandleTipNormal{
        color: #fff;
    }
    .ProfileAvatar{
        position: relative;
        overflow: hidden;
        margin: 0px auto;
        margin-top: 20px;
        width: 84px;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #272727;
        border-radius: 100px;
    }
    .ProfileAvatarDelete{
        cursor: pointer;
        right: 25px;
        top: 25px;
        position: absolute;
        z-index: 10;
    }
    .ProfileEditorSaveButton{
        width: 140px;
        height: 40px;
        color: #fff;
        border: 1px solid #333333;
        background: #272727;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;
        margin-top: 20px;
        cursor: pointer;
        font-size: 14px;
        margin: 0px auto;
        margin-top: 40px;
    }
}
.ant-input{
    padding: 4px 4px;
}
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper >textarea.ant-input{
    color: #fff;
}
.ant-input-textarea-show-count .ant-input-data-count {
    color: #fff;
}
.ProfileEditorHeader{
    display: flex;
    justify-content: space-between;
}
.ProfileEditorBackButton{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 120px;
}
.ProfileEditorRight{
    width: 120px;
}