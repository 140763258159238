.CreateVibeButton{
    width: 120px;
    height: 44px;
    color: #fff;
    border: 1px solid #333333;
    background: #272727;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
}
.CreateVibeButtonDisable{
    cursor:not-allowed;
    width: 120px;
    height: 44px;
    color: #606060;
    border: 1px solid #333333;
    background: #272727;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    font-weight: bold;
}
.CategoryOptionsTip{
    color: #fff;
}
.VibeInputBg{
    height: 42px;
    color: #fff;
    background: #0F1112;
    border: 1px solid #202225;
    border-radius: 4px;
    margin-top: 20px;
    padding: 0px 4px;
}
.NewVibeButton{
    width: 100px;
    height: 44px;
    border: 1px solid #202225;
    background: #0F1112;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    cursor: pointer;
}

.NewVibe{
    height: auto;
}
.ant-select-outlined.ant-select-multiple .ant-select-selection-item{
    border: none;
    background: #202225;
}
.ant-select-dropdown .ant-select-item-option-disabled {
    color: #fff;
}
.NewVibeImage{
    margin-top: 20px;
    width: 200px;
    height: 200px;
    overflow: hidden;
    position: relative;
}
.NewVibeImageDelete{
    cursor: pointer;
    right: 10px;
    top: 10px;
    position: absolute;
    z-index: 10;
}
.NewVibeLoading{
    display: flex;
    justify-content: center;
}
.NewVibeFunctionsButton{
    display: flex;
}
.NewVibeEmoji{
    margin-left: 20px;
}
.NewVibeButtons{
    margin-top: 20px;
    justify-content: space-between;
    display: flex;
}
.NewVibeTags{
    margin-top: 30px;
}
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled){
    background: none;
}
.NewVibeErrorText{
    color: #ff4d4f;
}
.ant-select-multiple .ant-select-selection-placeholder{
    color: #4B4B4B;
}