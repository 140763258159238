.EmojiButton{
    cursor: pointer;
    border: 1px solid #333333;
    background: #272727;
    border-radius: 4px;
    padding: 10px;
    align-items: center;
    width: 24px;
    height: 24px;
}
.em-emoji-picker{
    height: 100px;
}