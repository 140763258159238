.UserProfile {
  border-bottom: 1px solid #202225;
  padding: 30px;
  .UserProfileBack {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 120px;
    padding-bottom: 30px;
  }
  .UserProfileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .UserProfileAvatar {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      overflow: hidden;
    }
    .UserInfoHandle {
      color: #959798;
    }
    .UserInfoUnregistered {
      display: flex;
      align-items: center;
    }
    .UserInfo {
      margin-left: 10px;
    }
    .UserProfileButtons {
      display: flex;
      a {
        text-decoration: none;
      }
      .Editor {
        width: 100px;
        height: 40px;
        color: #fff;
        border: 1px solid #333333;
        background: #272727;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 20px;
      }
      .CopyLink {
        width: 100px;
        height: 40px;
        color: #fff;
        border: 1px solid #333333;
        background: #272727;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;

        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .UserMoreInfo {
    .UserBio {
      margin-top: 20px;
      white-space: pre-wrap;
    }
    .UserBio a {
      text-decoration: none;
      color: #1d9bf0;
    }
    .UserBio a:hover {
      text-decoration: underline;
    }

    .UserTags {
      margin-top: 20px;
    }
  }
}
.UserVibeListTitle {
  font-size: 18px;
  padding: 20px 0px 0px 20px;
}
.ShowProfileLoading {
  width: 100%;
  display: flex;
  height: 200px;
  justify-content: center;
}
.ProfileConnectWalletTitle {
  width: 100%;
  text-align: center;
  margin-top: 200px;
}
.ProfileConnectWalletButton {
  width: 140px;
  height: 40px;
  color: #fff;
  border: 1px solid #333333;
  background: #272727;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin: 20px auto 0;
}
.ProfileNoUser {
  width: 100%;
  text-align: center;
  color: #fff;
  margin-top: 100px;
}

.Basename {
  cursor: pointer;
  padding: 4px;
  border: 2px solid #155dfd;
  background: #155dfd;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: fit-content;
  flex-shrink: 0;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
}
.Basename img {
  display: block;
}
.BasenameIcon {
  border: 1px solid #fff;
  padding: 2px;
  border-radius: 200px;
  overflow: hidden;
  width: 14px;
  height: 14px;
  margin-top: 2px;
}
.BasenameValue {
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  margin-left: 6px;
}

@media only screen and (max-width: 576px) {
  .UserProfile .UserProfileHeader {
    flex-direction: column;
    align-items: start;
  }

  .UserProfileButtons {
    margin-top: 20px;
  }
}
