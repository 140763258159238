.ShortQuote {
    border: 1px solid #202225;
    border-radius: 4px;
    padding: 10px;
    color: #959798;
    background: #000;
    margin-top: 20px;
    display: flex;
}

.DetailQuote {
    cursor: pointer;
    border: 1px solid #202225;
    border-radius: 4px;
    padding: 10px;
}

.QuoteUser {
    align-items: center;
    font-size: 14px;
    display: flex;
}
.QuoteUserAvatar{
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100px;
}
.QuoteUserName {
    color: #fff;
    margin-left: 10px;
}
.QuoteUserHandle{
    color: #959798;
    margin-left: 4px;
}

.QuoteUserDate {
    color: #959798;
    margin-left: 10px;
}

.QuoteUserContent {
    display: flex;
    color: #fff;
    font-size: 14px;
    margin-top: 10px;
}
.ShortQuoteDot{
    width: calc(100% - 20px);
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    
}