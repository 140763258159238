.VibeDetail{
    width: 90%;
    margin: 0px auto;
    padding-top: 30px;
    padding-bottom: 30px;
}
.VibeDetailHeaderBack{
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 120px;
}
.VibeDetailTitle{
    font-size: 28px;
}
.VibeDetailInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
}
.VibeDetailUserAvatarAndHandle{
    cursor: pointer;
    display: flex;
}
.VibeDetailUserAvatar{
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 100px;
}
.VibeDetailUserNickname{

}
.VibeDetailUserHandle{
    color: #959798;
    font-size: 14px;
}
.VibeDetailUserDate{
    color: #959798;
}
.VibeDetailUser{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.VibeDetailUserInfo{
    justify-content: center;
    flex-direction: column;
    display: flex;
    margin-left: 10px;
}

.VibeDetailResource{
    margin-bottom: 20px;

}
.VibeDetailImage{
    margin-bottom: 20px;
}
.VibeDetailQuote{
    margin-bottom: 20px;
}
.VibeDetailTags{
    display: flex;
    margin-bottom: 20px;
}



.VibeDetailButtons{
    width: 100%;
    display: flex;
}
.VibeDetailButton{
    justify-content: center;
    width: 33%;
    display: flex;
    height: 50px;
    align-items: center;
    cursor: pointer;
}
.VibeDetailButtonTit{
    margin-left: 10px;
}
.BottomLine{
    border-bottom:1px solid #202225;
}
.VibeDetailContent{
    white-space: pre-wrap;
    margin-bottom: 20px;
}
.VibeDetailContent a{
    text-decoration: none;
    color: #1D9BF0;
}
.VibeDetailContent a:hover{
    text-decoration: underline;
}
.ShowVibeDetailLoading{
    width:100%;
    display: flex;
    height: 200px;
    justify-content: center;
}

@media only screen and (max-width: 576px) {
    .VibeDetailButtonTit{
        display: none;
    }
  
  }
