.CommentTitle{
    margin-top: 30px;
}
.CommentList{
    margin-top: 40px;
}
.CommentList li{
    margin-bottom: 20px;
    border-bottom:1px solid #202225;
    padding-bottom: 20px;
}
.CommentUser{
    cursor: pointer;
    align-items: center;
    font-size: 14px;
    display: flex;
}
.CommentUserAvatar{
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 100px;
}
.CommentUserName{
    margin-left: 10px;
}
.CommentUserHandle{
    color: #959798;
    margin-left: 4px;
}
.CommentUserDate{
    color: #959798;
    margin-left: 10px;
}
.CommentContent{
    white-space: pre-wrap;
    font-size: 14px;
    margin-top: 10px;
}
.CommentsLoadMore{
    width: 120px;
    height: 44px;
    border: 1px solid #202225;
    background: #0F1112;
    text-align: center;
    line-height: 44px;
    border-radius: 4px;
    margin: 0px auto;
    margin-top: 40px;
    cursor: pointer;
}
.CommentContent a{
    text-decoration: none;
    color: #1D9BF0;
}
.CommentContent a:hover{
    text-decoration: underline;
}
.NoComment{
    width: 100%;
    text-align: center;
    color: #959798;
    margin-top: 30px;
}
