.tags_container{
    height: auto;
    margin-top: 30px;
}
.tags{
    
    font-size: 14px;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: start;

}
.tags li{
    margin-bottom: 8px;;
}
.tag_uncheck{
    cursor: pointer;
    margin: 0px;
    color: #fff;
    list-style-type: none;
    padding: 6px 10px;
    border-radius: 30px;
    border:1px solid #e5e7eb;
    margin-right: 10px;
}
.tag_checked{
    cursor: pointer;
    margin: 0px;
    color: #000;
    list-style-type: none;
    padding: 6px 10px;
    border-radius: 30px;
    border:1px solid #fff;
    margin-right: 10px;
    background:#fff;
}
.tags_tip{
    color: #fff;
    height: 40px;
    line-height: 40px;
    display: flex;
}
.tags_tips_num{
    color: #4e5af5;
}


@media only screen and (max-width: 576px) {
    .tags{
        font-size: 12px;
    }
}