.CommentInputBg{
    height: 42px;
    color: #fff;
    background: #0F1112;
    border: 1px solid #202225;
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px;
}
.CommentButtonGroup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

}
.CommentButton{
    width: 100px;
    height: 40px;
    color: #fff;
    border: 1px solid #333333;
    background: #272727;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}
.CommentLoading{
    display: flex;
    justify-content: center;
}