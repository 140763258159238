@font-face {
  font-family: SFMonoRegular;
  src: url("./assets/font/GDSherpaBold.ttf");
}

body {
  color: #fff;
  margin: 0;
  /* font-family:SFMonoRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #070707;
  font-family: SFMonoRegular;
}

ul,li,p,h1,h2,h3,button,input,textarea{
  list-style-type: none;
  margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    padding-inline: 0px;
    padding-block: 0px;
    padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.App{
  max-width: 900px;
  width: 100%;
  height: auto;
  margin: 0px auto;
  display: flex;
}
.AppLeft{
  width: 259px;
  height: 100vh;

}
.AppLeftMenu{
  width: 259px;
  height: 100vh;
  position: fixed;
  border-right: 1px solid #202225;
}
.AppRight{
  max-width: 640px;
  width: 100%;
  height: auto;
}
.AppMoblieHeader{
  display: none;
}

@media only screen and (max-width: 576px) {
  .App{
    flex-direction: column;
  }
  .AppLeft{
    display: none;
  }
  
  .AppMoblieHeader{
    display: block;
  }
  .MoblieSplit{
    width: 100%;
    height: 70px;
  }
}