.Vibe{

}


.ant-modal .ant-modal-content{
    border: 1px solid #333333;
}
.VibeHeaderFixed{
    max-width: 640px;
    width: 100%;
    position: fixed;
    top: 0;
    border-bottom: 1px solid #202225;
    border-left: 1px solid #202225;
    background: #000;
}
.VibeHeader{
    width: calc(100% - 40px);
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}
.VibeHeaderFixedSplit{
    height: 88px;
}
.Select{
    height: 44px;
    width: 240px;
}
.ant-select .ant-select-arrow{
    color: #fff;
}


@media only screen and (max-width: 576px) {
    .Select{
        height: 44px;
        width: 140px;
    }
    .VibeHeaderFixed{

        border-left: 0px solid #000;
        position: relative;
        border-bottom:none;
        background: none;
        
    }
    .VibeHeaderFixedSplit{
        display: none;
    }
    .VibeHeader{
        padding: 20px 0px 0px;
    }
    
  }
